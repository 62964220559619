import { graphql } from 'gatsby'
import { theme } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts'
import { FlexCard } from '../Card'
import { isContentfulType } from '../../utils'

const ChartSet = (props) => {
  const { chartHeader, title, chartsCollection } = props
  const charts = chartsCollection.items.map((chart) => {
    return {
      type: chart.__typename,
      title: chart.title,
      subtitle: chart.subtitle,
      target: chart.target,
      unit: chart.unit || null,
      data: chart.dataEntries.map((entry, index) => {
        return {
          title: chart.title,
          tick: parseInt(chartHeader[index], 10),
          value: chart.unit === '%' ? parseFloat(entry) : parseInt(entry),
        }
      }),
    }
  })

  const axisColor = theme.colors.text.dark.secondary
  const lineColor = theme.colors.text.secondary.primary
  const referenceColor = theme.colors.status.warning
  const allCharts = charts.map((chart, index) => {
    if (isContentfulType(chart.type, 'contentful_LineChart')) {
      return (
        <FlexCard
          key={`linechart${index}`}
          width={{ md: 12 }}
          mediaWidth={{ md: 8 }}
          reverse
          media={
            <ResponsiveContainer width={'100%'} height={400}>
              <LineChart
                data={chart.data}
                margin={{ top: 20, right: 50, left: 50, bottom: 20 }}
              >
                <YAxis
                  type="number"
                  dataKey="value"
                  domain={[
                    (dataMin) => dataMin / 1.5,
                    (dataMax) => dataMax * 1.5,
                  ]}
                  orientation="left"
                  tick={false}
                  stroke={axisColor}
                  hide
                />
                <XAxis
                  dataKey="tick"
                  domain={['dataMin', 'dataMax']}
                  name="Time"
                  type="number"
                  stroke={axisColor}
                  interval={0}
                />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <ReferenceLine
                  y={chart.target}
                  stroke={referenceColor}
                  strokeDasharray="3 3"
                  label={{
                    value: `Target ${chart.target}%`,
                    position: 'left',
                    fill: referenceColor,
                  }}
                />
                <Tooltip
                  formatter={(value) => {
                    return [value, chart.title]
                  }}
                />
                <Line type="monotone" dataKey="value" stroke={lineColor}>
                  <LabelList
                    dataKey="value"
                    formatter={(value) => {
                      return `${value.toLocaleString('en')} ${chart.unit}`
                    }}
                    position="bottom"
                    offset={10}
                  />
                </Line>
              </LineChart>
            </ResponsiveContainer>
          }
        >
          <div>
            <Headline as={'span'} styledAs={'h2'} noMargin>
              {chart.title}
            </Headline>
            <p>{chart.subtitle}</p>
          </div>
        </FlexCard>
      )
    }
    if (isContentfulType(chart.type, 'contentful_PeopleChart')) {
      return (
        <FlexCard
          key={`barchart${index}`}
          width={{ md: 12 }}
          mediaWidth={{ md: 8 }}
          reverse
          media={
            <ResponsiveContainer width={'100%'} height={400}>
              <BarChart
                data={chart.data}
                margin={{ top: 20, right: 50, left: 50, bottom: 20 }}
              >
                <YAxis
                  type="number"
                  dataKey="value"
                  domain={[
                    (dataMin) => dataMin / 1.5,
                    (dataMax) => dataMax * 1.5,
                  ]}
                  orientation="left"
                  tick={false}
                  stroke={axisColor}
                  hide
                />
                <XAxis
                  dataKey="tick"
                  domain={['dataMin', 'dataMax']}
                  name="Time"
                  type="number"
                  stroke={axisColor}
                  interval={0}
                />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <ReferenceLine
                  y={chart.target}
                  stroke={referenceColor}
                  strokeDasharray="3 3"
                  label={{
                    value: `Target ${chart.target}%`,
                    position: 'left',
                    fill: referenceColor,
                  }}
                />
                <Tooltip
                  formatter={(value) => {
                    return [value, chart.title]
                  }}
                />
                <Bar
                  type="monotone"
                  dataKey="value"
                  fill={lineColor}
                  stroke={lineColor}
                  maxBarSize={50}
                >
                  <LabelList
                    dataKey="value"
                    formatter={(value) => {
                      return value.toLocaleString('en')
                    }}
                    position="top"
                    offset={10}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          }
        >
          <div>
            <Headline as={'span'} styledAs={'h2'} noMargin>
              {chart.title}
            </Headline>
            <p>{chart.subtitle}</p>
          </div>
        </FlexCard>
      )
    }
    return undefined
  })

  return (
    <>
      <FlexCard>
        <Headline as={'h2'}>{title}</Headline>
      </FlexCard>
      {allCharts}
    </>
  )
}

export const ChartSetFragment = graphql`
  fragment ChartSet on contentful_ChartSet {
    __typename
    sys {
      id
    }
    chartHeader
    title
    chartsCollection(limit: 5) {
      items {
        ... on contentful_LineChart {
          __typename
          title
          dataEntries
          lineSize
          subtitle
          target
          unit
        }
        ... on contentful_PeopleChart {
          __typename
          title
          dataEntries
        }
      }
    }
  }
`

export default ChartSet
